import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import ShieldIcon from '@mui/icons-material/Shield';
import TimelineIcon from '@mui/icons-material/Timeline';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';

const items = [
    {
        icon: <ShieldIcon />,
        title: 'Seller Inventory',
        description:
            'Get notified when a seller is or about to go out of stock in Amazon. Monitor sellers inventory level and take action to increase your sales and market share.',
        image: 'url("/static/inventory.svg")',
    },
    {
        icon: <TimelineIcon />,
        title: 'Price Tracking',
        description:
            'Monitor Competitor’s prices and get alerts when they change in Amazon to keep your prices competitive.',
        image: 'url("/static/price_engine.png")',
    },
    {
        icon: <CandlestickChartIcon />,
        title: 'Keyword Tracking',
        description:
            'Easily monitor and compare your product\'s keyword rankings with competitors in Amazon. Keep up with search position changes over time to optimize your visibility and stay ahead of the competition.',
        image: 'url("/static/keyword_ppc.png")',
    },
    {
        icon: <DevicesRoundedIcon />,
        title: 'Trend setters and Market Leaders',
        description:
            'Track the top sellers in your category and see what they are doing right in Amazon. Monitor their prices, reviews, and sales to stay ahead of the competition.',
        image: 'url("/static/trend_analytics.png")',
    },
];

export default function Features() {
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

    const handleItemClick = (index) => {
        setSelectedItemIndex(index);
    };

    const selectedFeature = items[selectedItemIndex];

    return (
        <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <div>
                        <Typography component="h2" variant="h4" color="text.primary">
                            Product features
                        </Typography>
                        {/*<Typography*/}
                        {/*    variant="body1"*/}
                        {/*    color="text.secondary"*/}
                        {/*    sx={{ mb: { xs: 2, sm: 4 } }}*/}
                        {/*>*/}
                        {/*    Here you can provide a brief overview of the key features of the*/}
                        {/*    product. For example, you could list the number of features, the types*/}
                        {/*    of features, add-ons, or the benefits of the features.*/}
                        {/*</Typography>*/}
                    </div>
                    <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
                        {items.map(({ title }, index) => (
                            <Chip
                                key={index}
                                label={title}
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index ? 'primary.light' : '';
                                        }
                                        return selectedItemIndex === index ? 'primary.light' : '';
                                    },
                                    background: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index ? 'none' : '';
                                        }
                                        return selectedItemIndex === index ? 'none' : '';
                                    },
                                    backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                                    '& .MuiChip-label': {
                                        color: selectedItemIndex === index ? '#fff' : '',
                                    },
                                }}
                            />
                        ))}
                    </Grid>
                    <Box
                        component={Card}
                        variant="outlined"
                        sx={{
                            display: { xs: 'auto', sm: 'none' },
                            mt: 4,
                        }}
                    >
                        {/*<Box*/}
                        {/*    sx={{*/}
                        {/*        backgroundImage: (theme) =>*/}
                        {/*            theme.palette.mode === 'light'*/}
                        {/*                ? items[selectedItemIndex].imageLight*/}
                        {/*                : items[selectedItemIndex].imageDark,*/}
                        {/*        backgroundSize: 'cover',*/}
                        {/*        backgroundPosition: 'center',*/}
                        {/*        minHeight: 280,*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Box sx={{ px: 2, pb: 2 }}>
                            <Typography color="text.primary" variant="body2" fontWeight="bold">
                                {selectedFeature.title}
                            </Typography>
                            <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                                {selectedFeature.description}
                            </Typography>
                            <Link
                                color="primary"
                                variant="body2"
                                fontWeight="bold"
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    '& > svg': { transition: '0.2s' },
                                    '&:hover > svg': { transform: 'translateX(2px)' },
                                }}
                            >
                                <span>Learn more</span>
                                <ChevronRightRoundedIcon
                                    fontSize="small"
                                    sx={{ mt: '1px', ml: '2px' }}
                                />
                            </Link>
                        </Box>
                    </Box>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        useFlexGap
                        sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
                    >
                        {items.map(({ icon, title, description }, index) => (
                            <Card
                                key={index}
                                variant="outlined"
                                component={Button}
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    p: 3,
                                    height: 'fit-content',
                                    width: '100%',
                                    background: 'none',
                                    backgroundColor:
                                        selectedItemIndex === index ? 'action.selected' : undefined,
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index
                                                ? 'primary.light'
                                                : 'grey.200';
                                        }
                                        return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        textAlign: 'left',
                                        flexDirection: { xs: 'column', md: 'row' },
                                        alignItems: { md: 'center' },
                                        gap: 2.5,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: (theme) => {
                                                if (theme.palette.mode === 'light') {
                                                    return selectedItemIndex === index
                                                        ? 'primary.main'
                                                        : 'grey.300';
                                                }
                                                return selectedItemIndex === index
                                                    ? 'primary.main'
                                                    : 'grey.700';
                                            },
                                        }}
                                    >
                                        {icon}
                                    </Box>
                                    <Box sx={{ textTransform: 'none' }}>
                                        <Typography
                                            color="text.primary"
                                            variant="body2"
                                            fontWeight="bold"
                                        >
                                            {title}
                                        </Typography>
                                        <Typography
                                            color="text.secondary"
                                            variant="body2"
                                            sx={{ my: 0.5 }}
                                        >
                                            {description}
                                        </Typography>
                                        <Link
                                            color="primary"
                                            variant="body2"
                                            fontWeight="bold"
                                            sx={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                '& > svg': { transition: '0.2s' },
                                                '&:hover > svg': { transform: 'translateX(2px)' },
                                            }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <span>Learn more</span>
                                            <ChevronRightRoundedIcon
                                                fontSize="small"
                                                sx={{ mt: '1px', ml: '2px' }}
                                            />
                                        </Link>
                                    </Box>
                                </Box>
                            </Card>
                        ))}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: { xs: 'none', sm: 'flex' },
                            pointerEvents: 'none',
                        }}
                    >
                        <Box
                            sx={{
                                m: 'auto',
                                width: '90%',
                                height: '100%',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundImage: items[selectedItemIndex].image,
                                backgroundRepeat: 'no-repeat',
                            }}
                        />
                    </Card>
                    {/*<Card*/}
                    {/*    variant="outlined"*/}
                    {/*    sx={{*/}
                    {/*        height: '100%',*/}
                    {/*        width: '100%',*/}
                    {/*        display: { xs: 'none', sm: 'flex' },*/}
                    {/*        pointerEvents: 'none',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Box*/}
                    {/*        sx={{*/}
                    {/*            m: 'auto',*/}
                    {/*            width: '100%',*/}
                    {/*            height: '100%',*/}
                    {/*            display: 'flex',*/}
                    {/*            flexDirection: 'column',*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <Box*/}
                    {/*            sx={{*/}
                    {/*                flex: '1',*/}
                    {/*                width: '100%',*/}
                    {/*                height: '100%',*/}
                    {/*                backgroundSize: 'cover',*/}
                    {/*                backgroundImage: items[selectedItemIndex].imageTop,*/}
                    {/*                backgroundRepeat: 'no-repeat',*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*        <Divider></Divider>*/}
                    {/*        <Box*/}
                    {/*            sx={{*/}
                    {/*                flex: '1',*/}
                    {/*                width: '100%',*/}
                    {/*                height: '100%',*/}
                    {/*                backgroundSize: 'cover',*/}
                    {/*                backgroundImage: items[selectedItemIndex].imageBottom,*/}
                    {/*                backgroundRepeat: 'no-repeat',*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </Box>*/}
                    {/*</Card>*/}
                </Grid>
            </Grid>
        </Container>
    );
}